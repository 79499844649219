<template>
  <div>
    <moban>
      <div class="forgetPage">
        <h2 class="title">
          <span>{{ $lang("forgetPage_title") }}</span>
          <i class="close el-icon-close" @click="closeHint" v-if="!isHint"></i>
          <i
            class="close el-icon-close"
            v-else-if="isHint"
            @click="closeForget"
          ></i>
        </h2>
        <div class="forget-content" v-if="isHint">
          <el-form
            :model="ruleForm"
            status-icon
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <!-- 电话 -->
            <div class="tel">
              <el-form-item prop="account">
                <el-input
                  v-model="ruleForm.account"
                  :placeholder="$lang('forgetPage_placeholder_phone')"
                ></el-input>
                <i class="account-icon el-icon-phone"></i>
              </el-form-item>
            </div>
            <!-- 验证码 -->

            <div class="auth-code">
              <el-form-item prop="code">
                <el-input
                  v-model="ruleForm.code"
                  :placeholder="$lang('forgetPage_placeholder_yzm')"
                ></el-input>
                <el-button @click="getAuthCode" :loading="loading">
                  {{ loading ? countDown + "s" : $lang("forgetPage_get_yzm") }}
                </el-button>
              </el-form-item>
            </div>
            <!-- 新密码 -->
            <div class="pwd">
              <el-form-item prop="pass">
                <el-input
                  v-model="ruleForm.pass"
                  :placeholder="$lang('forgetPage_placeholder_new_password')"
                  show-password
                ></el-input>
                <i class="account-icon el-icon-lock"></i>
              </el-form-item>
            </div>
            <div class="btn">
              <el-button type="primary" @click="confirm('ruleForm')">
                {{ $lang("forgetPage_btn_submit") }}
              </el-button>
              <el-button type="info" plain @click="$router.back()">
                {{ $lang("forgetPage_btn_cancel") }}
              </el-button>
            </div>
          </el-form>
        </div>
        <!-- 提示框 -->
        <div class="hint" v-else-if="!isHint">
          <div class="center">
            <img class="img" :src="hintData.imgurl" />
            <p class="hint-text">{{ hintData.text }}</p>
          </div>
          <div class="footer">
            <el-button type="primary" @click="goLogin"> 去登录 </el-button>
          </div>
        </div>
      </div>
    </moban>
  </div>
</template>

<script>
import moban from "@/components/indexIndexIndex/moban.vue";

import { checkAccount, checkPassword } from "@/utils/tools";

import { do_reset_passowrd, do_sendmsg } from "@/api/dologin";
import md5 from "md5";

export default {
  components: {
    moban,
  },
  data() {
    return {
      isHint: true,
      loading: false, //按钮加载
      countDown: 60, //倒计时
      hintData: {
        imgurl: require("@/assets/imgs/indexIndexIndex/yes1.png"),
        text: "修改成功",
      },
      ruleForm: {
        pass: "",
        account: "",
        code: "",
      },
      rules: {
        pass: [{ validator: checkPassword, trigger: "blur" }],
        account: [{ validator: checkAccount, trigger: "blur" }],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
  methods: {
    //去登录
    goLogin() {
      this.$router.push("/login");
    },
    closeHint() {
      this.isHint = true;
    },
    closeForget() {
      if (window.history.length <= 1) {
        this.$router.push({ path: "/" });
        return false;
      } else {
        this.$router.back();
      }
    },
    confirm(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let phone = this.ruleForm.account; //手机号
          let password = this.ruleForm.pass; //密码
          let yzm = "1234";
          let data = { phone, password, yzm };
          do_reset_passowrd(data)
            .then((res) => {
              this.isHint = false;
              console.log(res);
            })
            .then((err) => {
              console.log(err);
            })
            .finally(() => {
              this.logining = false;
            });
        } else {
          console.log("请输入验证信息");
          return false;
        }
      });
    },
    //获取验证码
    getAuthCode() {
      let phone = this.ruleForm.account; //手机号
      if (!phone) {
        this.$notify({
          type: "error",
          message: "请输入手机号",
        });
        return;
      }
      let time_stamp = Math.floor(new Date().getTime() / 1000) + ""; //时间戳秒级
      let sign = md5(md5(phone + "jianzhan" + time_stamp)); //数据签名
      let scene = "忘记密码";
      let data = { phone, time_stamp, sign, scene };

      this.logining = true;
      do_sendmsg(data)
        .then((res) => {
          console.log(res);
          this.loading = true;
          this.countDownFn();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.logining = false;
        });
    },

    // 倒计时
    countDownFn() {
      let timer = window.setInterval(() => {
        this.countDown--;
        this.loading = true;
        if (this.countDown < 0) {
          this.countDown = 60;
          this.loading = false;
          window.clearInterval(timer);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.forgetPage {
  width: 380px;
  height: 490px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: -7px 5px 4px 0px rgba(122, 122, 122, 0.14);
  .title {
    font-size: 24px;
    color: #333;
    text-align: center;
    position: relative;
    height: 60px;
    border-bottom: 1px solid #eee;
    margin-top: 30px;
  }
  .close {
    position: absolute;
    right: 30px;
    top: 4px;
    font-size: 24px;
    color: #666;
    cursor: pointer;
  }
  .forget-content {
    padding: 20px 30px;
    /deep/.el-form-item__content {
      margin-left: 0 !important;
    }
    .tel {
      position: relative;
      .area-code {
        position: absolute;
        left: 8px;
        top: 12px;
        color: #333;
        font-size: 14px;
        border-right: 1px solid #eee;
        padding-right: 6px;
      }
      /deep/.el-input__inner {
        padding-left: 40px;
        width: 322px;
        height: 42px;
      }

      .account-icon {
        position: absolute;
        top: 12px;
        left: 10px;
        font-size: 18px;
      }
    }
    //验证码
    .auth-code {
      display: flex;
      margin-top: 20px;
      /deep/.el-form-item__content {
        display: flex;
      }
      /deep/.el-form-item {
        margin-bottom: 0;
      }

      /deep/.el-input__inner {
        padding-left: 15px;
        width: 220px;
        height: 42px;
      }
      .el-input {
        width: 220px;
      }
      .el-button {
        width: 102px;
        padding: 0px;
        color: #299ffe;
        border: 1px solid #299ffe;
        font-size: 14px;
      }
      /deep/.el-button [class*="el-icon-"] + span {
        margin-left: -2px;
        font-size: 12px;
      }
    }
    //密码
    .pwd {
      margin-top: 20px;
      position: relative;
      /deep/.el-input__inner {
        padding-left: 40px;
        width: 322px;
        height: 42px;
      }
      .account-icon {
        position: absolute;
        top: 12px;
        left: 10px;
        font-size: 18px;
      }
    }
    .btn {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      /deep/.el-button {
        padding: 12px 40px !important;
      }
    }
  }
}
.hint {
  width: 380px;
  height: 390px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .center {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .img {
      width: 80px;
      height: 62px;
    }
    .hint-text {
      color: #299ffe;
      font-size: 14px;
      margin-top: 12px;
    }
  }
  .footer {
    height: 100px;
    height: 100px;
    /deep/.el-button {
      width: 300px;
      height: 42px;
      background-color: #fff;
      border: 1px solid #299ffe;
      color: #299ffe;
    }
    /deep/.el-button:hover {
      background-color: #299ffe;
      color: #fff;
    }
  }
}
</style>